import React, { useState } from 'react';
import {
    Nav,
    NavLink,
    Bars,
    NavMenu,
    NavBtn,
    NavBtnLink,
  } from './NavbarElements';
import secureLocalStorage from 'react-secure-storage';

export default function NavBar({ token, onLogoff }){
  const [isLogoffClicked, setIsLogoffClicked] = useState(false); // State to track if Logoff clicked
  const isLoggedIn = token !== null;

  let logTxt = isLoggedIn ? 'Logout '+secureLocalStorage.getItem('userID') || 'Please login' : 'Log In';
  console.log("logtxt1 "+logTxt);
  if ((isLogoffClicked&&token==null)||secureLocalStorage.getItem('userID')==null) {
    logTxt = 'Please log in'; // Change text when Logoff is clicked
  }
  console.log("logtxt2 "+logTxt);

  const handleLogoffClick = () => {
    setIsLogoffClicked(true); // Set state to indicate Logoff click
    onLogoff(); // Call the actual Logoff function
  };

  return (
    
    <>
    <Nav>
      <Bars />
      <NavMenu>
        <NavLink to='/'>
          About
        </NavLink>
        <NavLink to='/programs'>
          Programs
        </NavLink>
        <NavLink to='/tasks'>
          Tasks
        </NavLink>
        <NavLink to='/actions'>
          Actions
        </NavLink>
        <NavLink to='/programtask'>
          Programs And Tasks
        </NavLink>
        <NavLink to='/application'>
          Applications
        </NavLink>
        <NavLink to='/files'>
          Files
        </NavLink>
        {/*<NavLink to='/menuTest'>
          MenuTest
        </NavLink>*/}
        {/* Second Nav */}
        {/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
      </NavMenu>
      <NavBtn>
          {isLoggedIn ? (
            <NavBtnLink onClick={handleLogoffClick}>{logTxt}</NavBtnLink>
          ) : (
            <NavBtnLink to='/login'>{logTxt}</NavBtnLink>
          )}
      </NavBtn>
    </Nav>
  </>
  );
}