import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Grid } from "@mui/material";
import secureLocalStorage from 'react-secure-storage';

const ProgramAdd = () => {
  let navigate = useNavigate();
  const getCurrentTimestamp = () => {
    // Get the current timestamp in milliseconds
    const timestamp = new Date().toISOString();
    return timestamp;
  };
  const [formData, setFormData] = useState({
    SiteID: 100000,
    ChainCode: "FROM_REST",
    ProgramCode: "RESTTest",
    GroupName: "REACT",
    ActiveCode: "N",
    StatusCode: "V",
    ProgramStep: 1,
    ProgramClass: 1,
    ProgramPriority: 1,
    ProgramCalendarExecCode: 1,
    StartLagSeconds: 0,
    ProgramDesc: "Demo01",
    CaptureInitialDate: "19000101000000",
    CaptureGranularity: "yyyyMMddHHmmss",
    CaptureScript:
      "SELECT @capture_date=FORMAT(GETDATE(),'yyyyMMddHHmmss')",
    ProgramDependenciesActive: 0,
    CreatedDate: getCurrentTimestamp(),
    CreatedByUser: "2383",
    UpdatedDate: getCurrentTimestamp(),
    UpdatedByUser: "2383",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const cancelSubmission = () => {
    console.log("cancel");
    //setisCanceled(true);
    navigate("/programs");
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form data:", JSON.stringify(formData));
    const tokenString = JSON.parse(secureLocalStorage.getItem('userToken'));
        console.log("componentDidMount");
        console.log(tokenString.token);
    // Make a POST request to the API endpoint with the formData object
    fetch('https://exploradrest.azurewebsites.net/api/v1/exploradata/CreateProgram', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenString.token}`,
      },
      body: JSON.stringify(formData)
    })
    .then(response => {
      // Handle the API response here (if needed)
      console.log('Response:', JSON.stringify(response, null, 2));
      if (response.ok) {
        // Record inserted successfully
        console.log('Record inserted successfully.');
      } else {
        // Handle errors or failed requests
        console.error('Failed to insert record.');
      }
    })
    .catch(error => {
      // Handle any network or fetch-related errors
      console.error('Error:', error);
    });
    navigate("/programs");
  };

  return (
    
    <form onSubmit={handleSubmit} style={{ maxWidth: 700, margin: "auto" }}>
      <h2>Add a Program</h2>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {Object.keys(formData).map((key) => (
            <Grid item xs={4} key={key}>
              <TextField
                label={key}
                id={key}
                name={key}
                type="text"
                value={formData[key]}
                onChange={handleChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Button type="submit" variant="contained" color="primary" sx={{mt: 2, mr:2}}>
        Submit
      </Button>
      <Button type="cancel" variant="contained" color="primary" onClick={cancelSubmission} sx={{mt: 2, mr:2}}>
        Cancel
      </Button>
    </form>
  );
};

export default ProgramAdd;