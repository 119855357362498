import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Dropzone, FileMosaic } from "@files-ui/react";
//import AzureStorageTreeView from './AzureStorageTreeView';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import mime from 'react-native-mime-types';
import StorageSelect from './StorageSelect';
import Chip from '@mui/material/Chip';


export default function FileSystemNavigator() {
  const location = useLocation();
  //const containerName = "uploadreact";
  const [selectedStorage, setSelectedStorage] = useState('uploadreact');
  const [urlUpload,setUrlUpload] = useState("");
  secureLocalStorage.setItem('previousLocation', JSON.stringify(location));
  const [files, setFiles] = React.useState([]);
  //const [refreshTreeView, setRefreshTreeView] = React.useState(false); // Add state for refreshing
  const tokenAuth = JSON.parse(secureLocalStorage.getItem('userToken'));

  const makeAxiosRequest = async (token, url, method='get') => {
    console.log("makeAxiosRequest url ",url)
    console.log("makeAxiosRequest method",method)
    try {
        const config = {
            method: method,
            url: url,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };
        
        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 401) {
          window.location = '/login';
        }else{
            throw error;
        }
        
    }
  };

  useEffect(() => {
    // Fetch file list from Azure Storage API
    const tokenAuth = JSON.parse(secureLocalStorage.getItem('userToken'));
    const getAllFiles = async() => {
      if (selectedStorage==='uploadreact') {
        const data = makeAxiosRequest(tokenAuth.token,'https://exploradrest.azurewebsites.net/api/v1/explorafiles/GetAllBlobs?containerName='+selectedStorage);
        return data;
      }else if (selectedStorage==='beeplusexploras3') {
        const data = makeAxiosRequest(tokenAuth.token,'https://exploradrest.azurewebsites.net/api/v1/explorafiles/ListAllFilesS3?bucketName='+selectedStorage);
        return data;
      }

    }
    
     // call the function
     getAllFiles()
      .then(data => {console.log(data);
                      if (selectedStorage==='uploadreact') {
                        const filesFromContainer = data.map((file,id) => ({ name: file.name, id: id, type: mime.lookup(file.name), uploadStatus: "success"}));
                        setFiles(filesFromContainer);
                      }else if (selectedStorage==='beeplusexploras3') {
                        const filesFromContainer = data.map((file,id) => ({ name: file.key, id: id, type: mime.lookup(file.key), uploadStatus: "success"}));
                        setFiles(filesFromContainer);
                      }
                    })
      // make sure to catch any error
      .catch(console.error);
    
      //console.log("uploadedFiles files", files);

  }, [selectedStorage]);

  const updateFiles = (incommingFiles) => {
    //do something with the files
    //console.log("incomming files", incommingFiles);
    setFiles(incommingFiles);
    //even your own upload implementation
    //setRefreshTreeView(!refreshTreeView);
  };
  const removeFile = (id) => {
    //console.log("delete button clicked on file: " + id);
    const fileToDel = files.filter((x) => x.id === id)
    //console.log("delete button clicked on file: ",fileToDel[0].name);
    const tokenAuth = JSON.parse(secureLocalStorage.getItem('userToken'));
    if (selectedStorage==='uploadreact') {
      const response = makeAxiosRequest(tokenAuth.token,'https://exploradrest.azurewebsites.net/api/v1/explorafiles/DeleteBlob?containerName='+selectedStorage+'&fileName='+fileToDel[0].name, 'delete')
      console.log("removeFile",response);
    }else if (selectedStorage==='beeplusexploras3') {
      const response = makeAxiosRequest(tokenAuth.token,'https://exploradrest.azurewebsites.net/api/v1/explorafiles/DeleteFileS3?bucketName='+selectedStorage+'&keyName='+fileToDel[0].name, 'delete')
      console.log("removeFile",response);
    }
    
    setFiles(files.filter(x => x.id !== id));
  };

  const handleFinish = (uploadedFiles) => {
    //setRefreshTreeView(!refreshTreeView);
    console.log("advanced demo finish upload", uploadedFiles);
    //console.log(" handleFinish files", files);
  };

  const handleStorageSelect = (value) => {
    setSelectedStorage(value);
    if (value==="uploadreact"){
      setUrlUpload("https://exploradrest.azurewebsites.net/api/v1/explorafiles/UploadBlobs")
    }else if (value==="beeplusexploras3"){
      setUrlUpload("https://exploradrest.azurewebsites.net/api/v1/explorafiles/UploadFilesS3")
    }
  };

  return (
    <>
      {/*<AzureStorageTreeView refreshTreeView={refreshTreeView}/>*/}
      
      <p></p>
          <StorageSelect onSelect={handleStorageSelect}/>
      <p></p>
          <Chip label={"Container name: "+selectedStorage} variant="outlined" />
      <p></p>
      <Dropzone
        onChange={updateFiles}
        value={files}
        uploadConfig={{
            url: urlUpload,
            method: "POST",
            headers: { 'Authorization': `Bearer ${tokenAuth.token}`}, 
          }}
        onUploadFinish={handleFinish}
        
        //accept="image/*"
      >
        {files.map((file) => (
          <FileMosaic key={file.id} {...file} onDelete={removeFile} info />
        ))}
      </Dropzone>
      </>
  );
}