import React from 'react';
import B08DropDownItem from './B08DropDownItem';
import IconButton from '@mui/material/IconButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const B08DropDownFilters = ({ dropDownLists, setReloadGrid, onSelectChange }) => {

    //console.log("dropDownLists",dropDownLists);
    const handleClick = () => {
        console.log("reload data");
        setReloadGrid(true);
    }

    return (
        <div>
            {dropDownLists?.filter(item => item.hideField==="N"&&item.moduleCode==="B08-24").map((dropDown,index) => (
                <>
                {index===1?(
                    <IconButton onClick={() => {handleClick()}}>
                    <FilterAltIcon />
                    </IconButton>
                ):(null)
                }
                </>
            ))}
            {dropDownLists?.map((dropDown,index) => (
                //Fill dropdown
                <>
                {dropDown.hideField==="N"&&dropDown.moduleCode==="B08-24"?
                    (
                        <B08DropDownItem 
                                key={dropDown.lovListCode}
                                listCode={dropDown.lovListCode} 
                                languageID={1} 
                                label={dropDown.label} 
                                columnName={dropDown.columnName}
                                index={index}
                                defaultCode={dropDown.lovDefaultRefCode}
                                onSelectChange={onSelectChange}
                        />
                    ):(null)
                }
                </>
            ))}  
        </div>
        
    );
};

export default B08DropDownFilters;