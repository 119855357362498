import React from 'react';

function B08Results({ dataLists, dataBcaRefTranslated }) {
  //console.log("B08Results: ",dataLists);
  return (
    <table>
        <thead>
          <tr>
              {dataLists.filter((item) => item.graphicTypeCode === "Column").map((item, index) => (
                <th key={index}
                    style={{
                      wordBreak: 'break-word',
                      minWidth: item.columnMinWidth,
                      maxWidth: item.columnMaxWidth,
                      backgroundColor: item.tdBackgroundColor,
                      textAlign: item.tdTextAlign
                    }}>  
                    {item.label}  
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
              {dataBcaRefTranslated.map((itemData, index) => (
                  <tr key = {index}>
                    {dataLists.filter((item) => item.graphicTypeCode === "Column").map((item, index) => (
                                      <td
                                      key={index}
                                      style={{
                                        minWidth: item.columnMinWidth,
                                        maxWidth: item.columnMaxWidth,
                                        color: item.tdFontColor,
                                        fontWeight: item.tdFontWeight,
                                        fontStyle: item.tdFontStyle,
                                        textDecoration: item.tdTextDecoration,
                                        backgroundColor: item.tdBackgroundColor,
                                        textAlign: item.tdTextAlign
                                      }}
                                    >
                                        {item.columnName === "RowNumber"? 
                                        (<p>{itemData.rowNumber}</p>):
                                        item.columnName === "RefCode"? 
                                        (<p>{itemData.refCode}</p>): 
                                        item.columnName === "OrderBy" ? 
                                        (<p>{itemData.orderBy}</p>): 
                                        item.columnName === "RefEnglishText" ? 
                                        (<p>{itemData.refEnglishText}</p>): 
                                        item.columnName === "DefaultValue" ? 
                                        (<p>{itemData.defaultValue}</p>): 
                                        item.columnName === "RefEnglishDesc" ? 
                                        (<p>{itemData.refEnglishDesc}</p>): 
                                        item.columnName === "TranslationShortText" ? 
                                        (<p>{itemData.translationShortText}</p>): 
                                        item.columnName === "TranslationText" ? 
                                        (<p>{itemData.translationText}</p>): 
                                        item.columnName === "TranslationHint" ? 
                                        (<p>{itemData.translationHint}</p>):
                                        item.columnName === 'RefEnglishText' ? 
                                        (<p>  {itemData.refEnglishText}  </p>):
                                        item.columnName === 'TranslationText01' ? 
                                        (<p>  {itemData.translationText01}  </p>):
                                        item.columnName === 'TranslationText02' ? 
                                        (<p>  {itemData.translationText02}  </p>):
                                        item.columnName === 'TranslationText03' ? 
                                        (<p>  {itemData.translationText03}  </p>):
                                        item.columnName === 'TranslationText04' ? 
                                        (<p>  {itemData.translationText04}  </p>):
                                        item.columnName === 'TranslationText05' ? 
                                        (<p>  {itemData.translationText05}  </p>):
                                        item.columnName === 'TranslationText06' ? 
                                        (<p>  {itemData.translationText06}  </p>):
                                        item.columnName === 'TranslationText07' ? 
                                        (<p>  {itemData.translationText07}  </p>):
                                        item.columnName === 'TranslationText08' ? 
                                        (<p>  {itemData.translationText08}  </p>):
                                        item.columnName === 'TranslationText09' ? 
                                        (<p>  {itemData.translationText09}  </p>):
                                        item.columnName === 'TranslationText10' ? 
                                        (<p>  {itemData.translationText10}  </p>):
                                        item.columnName === 'TranslationText11' ? 
                                        (<p>  {itemData.translationText11}  </p>):
                                        item.columnName === 'TranslationText12' ? 
                                        (<p>  {itemData.translationText12}  </p>):
                                        item.columnName === 'TranslationText13' ? 
                                        (<p>  {itemData.translationText13}  </p>):
                                        item.columnName === 'TranslationText14' ? 
                                        (<p>  {itemData.translationText14}  </p>):
                                        item.columnName === 'TranslationText15' ? 
                                        (<p>  {itemData.translationText15}  </p>):
                                        item.columnName === 'TranslationText16' ? 
                                        (<p>  {itemData.translationText16}  </p>):
                                        item.columnName === 'TranslationText17' ? 
                                        (<p>  {itemData.translationText17}  </p>):
                                        item.columnName === 'TranslationText18' ? 
                                        (<p>  {itemData.translationText18}  </p>):
                                        item.columnName === 'TranslationText19' ? 
                                        (<p>  {itemData.translationText19}  </p>):
                                        item.columnName === 'TranslationText20' ? 
                                        (<p>  {itemData.translationText20}  </p>):
                                        item.columnName === 'Varchar01' ? 
                                        (<p>  {itemData.varchar01}  </p>):
                                        item.columnName === 'Varchar02' ? 
                                        (<p>  {itemData.varchar02}  </p>):
                                        item.columnName === 'Varchar03' ? 
                                        (<p>  {itemData.varchar03}  </p>):
                                        item.columnName === 'Varchar04' ? 
                                        (<p>  {itemData.varchar04}  </p>):
                                        item.columnName === 'Varchar05' ? 
                                        (<p>  {itemData.varchar05}  </p>):
                                        item.columnName === 'Varchar06' ? 
                                        (<p>  {itemData.varchar06}  </p>):
                                        item.columnName === 'Varchar07' ? 
                                        (<p>  {itemData.varchar07}  </p>):
                                        item.columnName === 'Varchar08' ? 
                                        (<p>  {itemData.varchar08}  </p>):
                                        item.columnName === 'Varchar09' ? 
                                        (<p>  {itemData.varchar09}  </p>):
                                        item.columnName === 'Varchar10' ? 
                                        (<p>  {itemData.varchar10}  </p>):
                                        item.columnName === 'Varchar11' ? 
                                        (<p>  {itemData.varchar11}  </p>):
                                        item.columnName === 'Varchar12' ? 
                                        (<p>  {itemData.varchar12}  </p>):
                                        item.columnName === 'Varchar13' ? 
                                        (<p>  {itemData.varchar13}  </p>):
                                        item.columnName === 'Varchar14' ? 
                                        (<p>  {itemData.varchar14}  </p>):
                                        item.columnName === 'Varchar15' ? 
                                        (<p>  {itemData.varchar15}  </p>):
                                        item.columnName === 'Varchar16' ? 
                                        (<p>  {itemData.varchar16}  </p>):
                                        item.columnName === 'Varchar17' ? 
                                        (<p>  {itemData.varchar17}  </p>):
                                        item.columnName === 'Varchar18' ? 
                                        (<p>  {itemData.varchar18}  </p>):
                                        item.columnName === 'Varchar19' ? 
                                        (<p>  {itemData.varchar19}  </p>):
                                        item.columnName === 'Varchar20' ? 
                                        (<p>  {itemData.varchar20}  </p>):
                                        item.columnName === 'Varchar21' ? 
                                        (<p>  {itemData.varchar21}  </p>):
                                        item.columnName === 'Varchar22' ? 
                                        (<p>  {itemData.varchar22}  </p>):
                                        item.columnName === 'Varchar23' ? 
                                        (<p>  {itemData.varchar23}  </p>):
                                        item.columnName === 'Varchar24' ? 
                                        (<p>  {itemData.varchar24}  </p>):
                                        item.columnName === 'Varchar25' ? 
                                        (<p>  {itemData.varchar25}  </p>):
                                        item.columnName === 'Varchar26' ? 
                                        (<p>  {itemData.varchar26}  </p>):
                                        item.columnName === 'Varchar27' ? 
                                        (<p>  {itemData.varchar27}  </p>):
                                        item.columnName === 'Varchar28' ? 
                                        (<p>  {itemData.varchar28}  </p>):
                                        item.columnName === 'Varchar29' ? 
                                        (<p>  {itemData.varchar29}  </p>):
                                        item.columnName === 'Varchar30' ? 
                                        (<p>  {itemData.varchar30}  </p>):
                                        item.columnName === 'Varchar31' ? 
                                        (<p>  {itemData.varchar31}  </p>):
                                        item.columnName === 'Varchar32' ? 
                                        (<p>  {itemData.varchar32}  </p>):
                                        item.columnName === 'Varchar33' ? 
                                        (<p>  {itemData.varchar33}  </p>):
                                        item.columnName === 'Varchar34' ? 
                                        (<p>  {itemData.varchar34}  </p>):
                                        item.columnName === 'Varchar35' ? 
                                        (<p>  {itemData.varchar35}  </p>):
                                        item.columnName === 'Varchar36' ? 
                                        (<p>  {itemData.varchar36}  </p>):
                                        item.columnName === 'Varchar37' ? 
                                        (<p>  {itemData.varchar37}  </p>):
                                        item.columnName === 'Varchar38' ? 
                                        (<p>  {itemData.varchar38}  </p>):
                                        item.columnName === 'Varchar39' ? 
                                        (<p>  {itemData.varchar39}  </p>):
                                        item.columnName === 'Varchar40' ? 
                                        (<p>  {itemData.varchar40}  </p>):
                                        item.columnName === 'Varchar41' ? 
                                        (<p>  {itemData.varchar41}  </p>):
                                        item.columnName === 'Varchar42' ? 
                                        (<p>  {itemData.varchar42}  </p>):
                                        item.columnName === 'Varchar43' ? 
                                        (<p>  {itemData.varchar43}  </p>):
                                        item.columnName === 'Varchar44' ? 
                                        (<p>  {itemData.varchar44}  </p>):
                                        item.columnName === 'Varchar45' ? 
                                        (<p>  {itemData.varchar45}  </p>):
                                        item.columnName === 'Varchar46' ? 
                                        (<p>  {itemData.varchar46}  </p>):
                                        item.columnName === 'Varchar47' ? 
                                        (<p>  {itemData.varchar47}  </p>):
                                        item.columnName === 'Varchar48' ? 
                                        (<p>  {itemData.varchar48}  </p>):
                                        item.columnName === 'Varchar49' ? 
                                        (<p>  {itemData.varchar49}  </p>):
                                        item.columnName === 'Varchar50' ? 
                                        (<p>  {itemData.varchar50}  </p>):
                                        item.columnName === 'Varchar51' ? 
                                        (<p>  {itemData.varchar51}  </p>):
                                        item.columnName === 'Varchar52' ? 
                                        (<p>  {itemData.varchar52}  </p>):
                                        item.columnName === 'Varchar053' ? 
                                        (<p>  {itemData.varchar53}  </p>):
                                        item.columnName === 'Varchar54' ? 
                                        (<p>  {itemData.varchar54}  </p>):
                                        item.columnName === 'Varchar55' ? 
                                        (<p>  {itemData.varchar55}  </p>):
                                        item.columnName === 'Varchar56' ? 
                                        (<p>  {itemData.varchar56}  </p>):
                                        item.columnName === 'Varchar57' ? 
                                        (<p>  {itemData.varchar57}  </p>):
                                        item.columnName === 'Varchar58' ? 
                                        (<p>  {itemData.varchar58}  </p>):
                                        item.columnName === 'Varchar59' ? 
                                        (<p>  {itemData.varchar59}  </p>):
                                        item.columnName === 'Varchar60' ? 
                                        (<p>  {itemData.varchar60}  </p>):
                                        item.columnName === 'Varchar61' ? 
                                        (<p>  {itemData.varchar61}  </p>):
                                        item.columnName === 'Varchar62' ? 
                                        (<p>  {itemData.varchar62}  </p>):
                                        item.columnName === 'Varchar62' ? 
                                        (<p>  {itemData.varchar63}  </p>):
                                        item.columnName === 'Varchar64' ? 
                                        (<p>  {itemData.varchar64}  </p>):
                                        item.columnName === 'Varchar65' ? 
                                        (<p>  {itemData.varchar65}  </p>):
                                        item.columnName === 'Varchar66' ? 
                                        (<p>  {itemData.varchar66}  </p>):
                                        item.columnName === 'Varchar67' ? 
                                        (<p>  {itemData.varchar67}  </p>):
                                        item.columnName === 'Varchar68' ? 
                                        (<p>  {itemData.varchar68}  </p>):
                                        item.columnName === 'Varchar69' ? 
                                        (<p>  {itemData.varchar69}  </p>):
                                        item.columnName === 'Varchar70' ? 
                                        (<p>  {itemData.varchar70}  </p>):
                                        item.columnName === 'Varchar71' ? 
                                        (<p>  {itemData.varchar71}  </p>):
                                        item.columnName === 'Varchar72' ? 
                                        (<p>  {itemData.varchar72}  </p>):
                                        item.columnName === 'Varchar73' ? 
                                        (<p>  {itemData.varchar73}  </p>):
                                        item.columnName === 'Varchar74' ? 
                                        (<p>  {itemData.varchar74}  </p>):
                                        item.columnName === 'Varchar75' ? 
                                        (<p>  {itemData.varchar75}  </p>):
                                        item.columnName === 'Varchar76' ? 
                                        (<p>  {itemData.varchar76}  </p>):
                                        item.columnName === 'Varchar77' ? 
                                        (<p>  {itemData.varchar77}  </p>):
                                        item.columnName === 'Varchar78' ? 
                                        (<p>  {itemData.varchar78}  </p>):
                                        item.columnName === 'Varchar79' ? 
                                        (<p>  {itemData.varchar79}  </p>):
                                        item.columnName === 'Varchar80' ? 
                                        (<p>  {itemData.varchar80}  </p>):
                                        item.columnName === 'Varchar81' ? 
                                        (<p>  {itemData.varchar81}  </p>):
                                        item.columnName === 'Varchar82' ? 
                                        (<p>  {itemData.varchar82}  </p>):
                                        item.columnName === 'Varchar83' ? 
                                        (<p>  {itemData.varchar83}  </p>):
                                        item.columnName === 'Varchar84' ? 
                                        (<p>  {itemData.varchar84}  </p>):
                                        item.columnName === 'Varchar85' ? 
                                        (<p>  {itemData.varchar85}  </p>):
                                        item.columnName === 'Varchar86' ? 
                                        (<p>  {itemData.varchar86}  </p>):
                                        item.columnName === 'Varchar87' ? 
                                        (<p>  {itemData.varchar87}  </p>):
                                        item.columnName === 'Varchar88' ? 
                                        (<p>  {itemData.varchar88}  </p>):
                                        item.columnName === 'Varchar89' ? 
                                        (<p>  {itemData.varchar89}  </p>):
                                        item.columnName === 'Varchar90' ? 
                                        (<p>  {itemData.varchar90}  </p>):
                                        item.columnName === 'Varchar91' ? 
                                        (<p>  {itemData.varchar91}  </p>):
                                        item.columnName === 'Varchar92' ? 
                                        (<p>  {itemData.varchar92}  </p>):
                                        item.columnName === 'Varchar93' ? 
                                        (<p>  {itemData.varchar93}  </p>):
                                        item.columnName === 'Varchar94' ? 
                                        (<p>  {itemData.varchar94}  </p>):
                                        item.columnName === 'Varchar95' ? 
                                        (<p>  {itemData.varchar95}  </p>):
                                        item.columnName === 'Varchar96' ? 
                                        (<p>  {itemData.varchar96}  </p>):
                                        item.columnName === 'Varchar97' ? 
                                        (<p>  {itemData.varchar97}  </p>):
                                        item.columnName === 'Varchar98' ? 
                                        (<p>  {itemData.varchar98}  </p>):
                                        item.columnName === 'Varchar99' ? 
                                        (<p>  {itemData.varchar99}  </p>):
                                        item.columnName === 'NVarchar01' ? 
                                        (<p>  {itemData.nVarchar01}  </p>):
                                        item.columnName === 'NVarchar02' ? 
                                        (<p>  {itemData.nVarchar02}  </p>):
                                        item.columnName === 'NVarchar03' ? 
                                        (<p>  {itemData.nVarchar03}  </p>):
                                        item.columnName === 'Float01' ? 
                                        (<p>  {itemData.float01}  </p>):
                                        item.columnName === 'Float02' ? 
                                        (<p>  {itemData.float02}  </p>):
                                        item.columnName === 'Float03' ? 
                                        (<p>  {itemData.float03}  </p>):
                                        item.columnName === 'Float04' ? 
                                        (<p>  {itemData.float04}  </p>):
                                        item.columnName === 'Float05' ? 
                                        (<p>  {itemData.float05}  </p>):
                                        item.columnName === 'Float06' ? 
                                        (<p>  {itemData.float06}  </p>):
                                        item.columnName === 'Float07' ? 
                                        (<p>  {itemData.float07}  </p>):
                                        item.columnName === 'Float08' ? 
                                        (<p>  {itemData.float08}  </p>):
                                        item.columnName === 'Float09' ? 
                                        (<p>  {itemData.float09}  </p>):
                                        item.columnName === 'Float10' ? 
                                        (<p>  {itemData.float10}  </p>):
                                        item.columnName === 'Float11' ? 
                                        (<p>  {itemData.float11}  </p>):
                                        item.columnName === 'Float12' ? 
                                        (<p>  {itemData.float12}  </p>):
                                        item.columnName === 'Float13' ? 
                                        (<p>  {itemData.float13}  </p>):
                                        item.columnName === 'Float14' ? 
                                        (<p>  {itemData.float14}  </p>):
                                        item.columnName === 'Float15' ? 
                                        (<p>  {itemData.float15}  </p>):
                                        item.columnName === 'Float16' ? 
                                        (<p>  {itemData.float16}  </p>):
                                        item.columnName === 'Float17' ? 
                                        (<p>  {itemData.float17}  </p>):
                                        item.columnName === 'Float18' ? 
                                        (<p>  {itemData.float18}  </p>):
                                        item.columnName === 'Float19' ? 
                                        (<p>  {itemData.float19}  </p>):
                                        item.columnName === 'Float20' ? 
                                        (<p>  {itemData.float20}  </p>):
                                        item.columnName === 'Float21' ? 
                                        (<p>  {itemData.float21}  </p>):
                                        item.columnName === 'Float22' ? 
                                        (<p>  {itemData.float22}  </p>):
                                        item.columnName === 'Float23' ? 
                                        (<p>  {itemData.float23}  </p>):
                                        item.columnName === 'Float24' ? 
                                        (<p>  {itemData.float24}  </p>):
                                        item.columnName === 'Float25' ? 
                                        (<p>  {itemData.float25}  </p>):
                                        item.columnName === 'Float26' ? 
                                        (<p>  {itemData.float26}  </p>):
                                        item.columnName === 'Float27' ? 
                                        (<p>  {itemData.float27}  </p>):
                                        item.columnName === 'Float28' ? 
                                        (<p>  {itemData.float28}  </p>):
                                        item.columnName === 'Float29' ? 
                                        (<p>  {itemData.float29}  </p>):
                                        item.columnName === 'Float30' ? 
                                        (<p>  {itemData.float30}  </p>):
                                        item.columnName === 'Float31' ? 
                                        (<p>  {itemData.float31}  </p>):
                                        item.columnName === 'Float32' ? 
                                        (<p>  {itemData.float32}  </p>):
                                        item.columnName === 'Float33' ? 
                                        (<p>  {itemData.float33}  </p>):
                                        item.columnName === 'Float34' ? 
                                        (<p>  {itemData.float34}  </p>):
                                        item.columnName === 'Float35' ? 
                                        (<p>  {itemData.float35}  </p>):
                                        item.columnName === 'Float36' ? 
                                        (<p>  {itemData.float36}  </p>):
                                        item.columnName === 'Float37' ? 
                                        (<p>  {itemData.float37}  </p>):
                                        item.columnName === 'Float38' ? 
                                        (<p>  {itemData.float38}  </p>):
                                        item.columnName === 'Float39' ? 
                                        (<p>  {itemData.float39}  </p>):
                                        item.columnName === 'Float40' ? 
                                        (<p>  {itemData.float40}  </p>):
                                        item.columnName === 'Float41' ? 
                                        (<p>  {itemData.float41}  </p>):
                                        item.columnName === 'Float42' ? 
                                        (<p>  {itemData.float42}  </p>):
                                        item.columnName === 'Float43' ? 
                                        (<p>  {itemData.float43}  </p>):
                                        item.columnName === 'Float44' ? 
                                        (<p>  {itemData.float44}  </p>):
                                        item.columnName === 'Float45' ? 
                                        (<p>  {itemData.float45}  </p>):
                                        item.columnName === 'Float46' ? 
                                        (<p>  {itemData.float46}  </p>):
                                        item.columnName === 'Float47' ? 
                                        (<p>  {itemData.float47}  </p>):
                                        item.columnName === 'Float48' ? 
                                        (<p>  {itemData.float48}  </p>):
                                        item.columnName === 'Float49' ? 
                                        (<p>  {itemData.float49}  </p>):
                                        item.columnName === 'Float50' ? 
                                        (<p>  {itemData.float50}  </p>):
                                        item.columnName === 'Float51' ? 
                                        (<p>  {itemData.float51}  </p>):
                                        item.columnName === 'Float52' ? 
                                        (<p>  {itemData.float52}  </p>):
                                        item.columnName === 'Float053' ? 
                                        (<p>  {itemData.float53}  </p>):
                                        item.columnName === 'Float54' ? 
                                        (<p>  {itemData.float54}  </p>):
                                        item.columnName === 'Float55' ? 
                                        (<p>  {itemData.float55}  </p>):
                                        item.columnName === 'Float56' ? 
                                        (<p>  {itemData.float56}  </p>):
                                        item.columnName === 'Float57' ? 
                                        (<p>  {itemData.float57}  </p>):
                                        item.columnName === 'Float58' ? 
                                        (<p>  {itemData.float58}  </p>):
                                        item.columnName === 'Float59' ? 
                                        (<p>  {itemData.float59}  </p>):
                                        item.columnName === 'Float60' ? 
                                        (<p>  {itemData.float60}  </p>):
                                        item.columnName === 'Float61' ? 
                                        (<p>  {itemData.float61}  </p>):
                                        item.columnName === 'Float62' ? 
                                        (<p>  {itemData.float62}  </p>):
                                        item.columnName === 'Float62' ? 
                                        (<p>  {itemData.float63}  </p>):
                                        item.columnName === 'Float64' ? 
                                        (<p>  {itemData.float64}  </p>):
                                        item.columnName === 'Float65' ? 
                                        (<p>  {itemData.float65}  </p>):
                                        item.columnName === 'Float66' ? 
                                        (<p>  {itemData.float66}  </p>):
                                        item.columnName === 'Float67' ? 
                                        (<p>  {itemData.float67}  </p>):
                                        item.columnName === 'Float68' ? 
                                        (<p>  {itemData.float68}  </p>):
                                        item.columnName === 'Float69' ? 
                                        (<p>  {itemData.float69}  </p>):
                                        item.columnName === 'Float70' ? 
                                        (<p>  {itemData.float70}  </p>):
                                        item.columnName === 'Float71' ? 
                                        (<p>  {itemData.float71}  </p>):
                                        item.columnName === 'Float72' ? 
                                        (<p>  {itemData.float72}  </p>):
                                        item.columnName === 'Float73' ? 
                                        (<p>  {itemData.float73}  </p>):
                                        item.columnName === 'Float74' ? 
                                        (<p>  {itemData.float74}  </p>):
                                        item.columnName === 'Float75' ? 
                                        (<p>  {itemData.float75}  </p>):
                                        item.columnName === 'Float76' ? 
                                        (<p>  {itemData.float76}  </p>):
                                        item.columnName === 'Float77' ? 
                                        (<p>  {itemData.float77}  </p>):
                                        item.columnName === 'Float78' ? 
                                        (<p>  {itemData.float78}  </p>):
                                        item.columnName === 'Float79' ? 
                                        (<p>  {itemData.float79}  </p>):
                                        item.columnName === 'Float80' ? 
                                        (<p>  {itemData.float80}  </p>):
                                        item.columnName === 'Float81' ? 
                                        (<p>  {itemData.float81}  </p>):
                                        item.columnName === 'Float82' ? 
                                        (<p>  {itemData.float82}  </p>):
                                        item.columnName === 'Float83' ? 
                                        (<p>  {itemData.float83}  </p>):
                                        item.columnName === 'Float84' ? 
                                        (<p>  {itemData.float84}  </p>):
                                        item.columnName === 'Float85' ? 
                                        (<p>  {itemData.float85}  </p>):
                                        item.columnName === 'Float86' ? 
                                        (<p>  {itemData.float86}  </p>):
                                        item.columnName === 'Float87' ? 
                                        (<p>  {itemData.float87}  </p>):
                                        item.columnName === 'Float88' ? 
                                        (<p>  {itemData.float88}  </p>):
                                        item.columnName === 'Float89' ? 
                                        (<p>  {itemData.float89}  </p>):
                                        item.columnName === 'Float90' ? 
                                        (<p>  {itemData.float90}  </p>):
                                        item.columnName === 'Float91' ? 
                                        (<p>  {itemData.float91}  </p>):
                                        item.columnName === 'Float92' ? 
                                        (<p>  {itemData.float92}  </p>):
                                        item.columnName === 'Float93' ? 
                                        (<p>  {itemData.float93}  </p>):
                                        item.columnName === 'Float94' ? 
                                        (<p>  {itemData.float94}  </p>):
                                        item.columnName === 'Float95' ? 
                                        (<p>  {itemData.float95}  </p>):
                                        item.columnName === 'Float96' ? 
                                        (<p>  {itemData.float96}  </p>):
                                        item.columnName === 'Float97' ? 
                                        (<p>  {itemData.float97}  </p>):
                                        item.columnName === 'Float98' ? 
                                        (<p>  {itemData.float98}  </p>):
                                        item.columnName === 'Float99' ? 
                                        (<p>  {itemData.float99}  </p>):null
                                        }
                                      </td>
                                  ))}
                  </tr>
                ))}
        </tbody>
      </table>
  );
}

export default B08Results;