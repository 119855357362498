import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Grid, MenuItem } from "@mui/material";
import secureLocalStorage from 'react-secure-storage';

const TaskAdd = () => {
  let navigate = useNavigate();
  const getCurrentTimestamp = () => {
    // Get the current timestamp in milliseconds
    const timestamp = new Date().toISOString();
    return timestamp;
  };
  const [formData, setFormData] = useState({
    SiteID: 100000,
    ProgramID: 100,
    ProgramCode: "",
    //TaskID: 10,
    TaskNumber: 10,
    StatusCode: "V",
    TaskScript: "SELECT * FROM TEST WHERE 12=1",
    ActionCode: "INSERT",
    TargetCatalog: "BEETST01",
    TargetSchema: "TEST",
    TargetTable: "INSERT_TEST_ROWS",
    TaskDesc: "Sample for WebApp",
    RefID: null,
    RunID: null,
    TaskParameter01: "",
    TaskParameter02: "",
    TaskParameter03: "",
    TaskParameter04: "",
    TaskParameter05: "",
    TaskParameter06: "",
    TaskParameter07: "",
    TaskParameter08: "",
    TaskParameter09: "",
    TaskParameter10: "",
    TaskParameter11: "",
    CreatedDate: getCurrentTimestamp(),
    CreatedByUser: "",
    UpdatedDate: null,
    UpdatedByUser: ""
  }
  );

  const [programCodes, setProgramCodes] = useState([]);
  //const [isCanceled, setisCanceled] = useState(false);

  const getPrograms = async (tokenAuth) => {
    try {
      const config = {
        method: 'get',
        url: 'https://exploradrest.azurewebsites.net/api/v1/exploradata/GetPrograms',
        headers: {
          'Authorization': `Bearer ${tokenAuth}`,
        }
      };
      const response = await axios(config);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  useEffect(() => {
    async function fetchProgramCodes() {
      try {
        const tokenString = JSON.parse(secureLocalStorage.getItem('userToken'));
        const programs = await getPrograms(tokenString.token);
        //console.log(programs.ProgramCode);
        setProgramCodes(programs);
      } catch (error) {
        console.error('Error fetching program codes:', error);
      }
    }
    
    fetchProgramCodes();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    console.log("handleChange detected");
  };

  const cancelSubmission = () => {
    console.log("cancel");
    //setisCanceled(true);
    navigate("/tasks");
  }

  const handleSubmit = (event) => {
    console.log("submit event");
    event.preventDefault();
    console.log("Form data:", formData);
    const tokenString = JSON.parse(secureLocalStorage.getItem('userToken'));
        console.log("componentDidMount");
        console.log(tokenString.token);
    // Make a POST request to the API endpoint with the formData object
    fetch('https://exploradrest.azurewebsites.net/api/v1/exploradata/CreateTask', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenString.token}`,
      },
      body: JSON.stringify(formData)
    })
    .then(response => {
      // Handle the API response here (if needed)
      console.log(response.text)
      if (response.ok) {
        // Record inserted successfully
        console.log('Record inserted successfully.');
      } else {
        // Handle errors or failed requests
        console.error('Failed to insert record.');
      }
    })
    .catch(error => {
      // Handle any network or fetch-related errors
      console.error('Error:', error);
    });
    navigate("/tasks");
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: 700, margin: "auto" }}>
      <h2>Add a Task</h2>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          {Object.keys(formData)
            .filter(key => !key.includes("ID")).map((key) => (
            <Grid item xs={4} key={key}>
              {key === "ProgramCode" ? ( // Check if the key is "ProgramCode"
                <TextField
                  label="ProgramCode"
                  id={key}
                  name={key}
                  value={formData[key]}
                  select
                  helperText="Please select a program"
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                >
                  {programCodes.map(code => (
                    <MenuItem key={code.programID} value={code.programCode}>
                      {code.programCode}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <TextField
                  label={key}
                  id={key}
                  name={key}
                  type="text"
                  value={formData[key]}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  margin="normal"
                  variant="outlined"
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
      <Button type="submit" variant="contained" color="primary" sx={{mt: 2, mr:2}}>
        Submit
      </Button>
      <Button type="cancel" variant="contained" color="primary" onClick={cancelSubmission} sx={{mt: 2, mr:2}}>
        Cancel
      </Button>
    </form>
  );
  
};

export default TaskAdd;