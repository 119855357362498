import React, { useState, useEffect, useCallback } from 'react';
import { Link,useLocation } from 'react-router-dom';
import Moment from 'react-moment';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';

const Application = () => {
    const location = useLocation();
    secureLocalStorage.setItem('previousLocation', JSON.stringify(location));
    const [metaApplicationGroups, setMetaApplicationGroups] = useState([]);
    const [metaApplications, setMetaApplications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [softwareVersionText, setSoftwareVersionText] = useState([]);
    const [footerTexts, setFooterTexts] = useState([]);
    const [metaDataUser, setMetaDataUser] = useState([]);

    const populateMetaApplication = useCallback(async () => {
        const tokenAuth = JSON.parse(secureLocalStorage.getItem('userToken'));
        const user = secureLocalStorage.getItem('userID');
        //console.log(user);
        //console.log(tokenAuth.token);
        try {
            const data = await makeAxiosRequest(tokenAuth.token, 'https://exploradrest.azurewebsites.net/api/v1/exploradata/GetApplications?userID=' + user);
            const dataGroup = await makeAxiosRequest(tokenAuth.token, 'https://exploradrest.azurewebsites.net/api/v1/exploradata/GetApplicationGroups?userID=' + user);
            const dataUser = await makeAxiosRequest(tokenAuth.token, 'https://exploradrest.azurewebsites.net/api/v1/exploradata/GetUserDetails?userID=' + user);
            const datavers = await makeAxiosRequest(tokenAuth.token, 'https://exploradrest.azurewebsites.net/api/v1/exploradata/GetSoftwareVersion?siteID=' + data[0].siteID);
            const datafoot = await makeAxiosRequest(tokenAuth.token, 'https://exploradrest.azurewebsites.net/api/v1/exploradata/GetApplicationFooter?userCode=' + user);

            setMetaApplicationGroups(dataGroup);
            setMetaApplications(data);
            //console.log("Application data: ",data);
            setSoftwareVersionText(datavers);
            setFooterTexts(datafoot);
            //console.log("dataUser",dataUser);
            setMetaDataUser(dataUser);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        populateMetaApplication();
    }, [populateMetaApplication]);


    const makeAxiosRequest = async (token, url) => {
        //console.log("makeAxiosRequest")
        try {
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
            
            const response = await axios(config);
            return response.data;
        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 401) {
            window.location = '/login';
            }else{
                throw error;
            }
            
        }
    };

    const renderMetaApplicationView = () => {

        let date = new Date();
        
        let publishingCoText = footerTexts[0].displayText;
        let versionText = softwareVersionText[0].versionText;

        const buttonStyle = {
                                verticalAlign: 'middle',
                                textAlign: 'center',
                                padding: '20px 5px',
                                fontSize: '22px',
                                fontWeight: 'bold',
                                marginTop: '-2px',
                                marginBottom: '5px',
                                width: '300px',
                                height: '80px',
                                border: '1px solid rgb(204, 204, 204)',
                                borderRadius: '4px',
                                color: 'rgb(88, 86, 86)',
                                backgroundColor: 'rgb(191, 201, 202)',
                                marginLeft: '35px',
                                textDecoration: 'none!important'
                            };
        return (
            <div>
                {metaApplicationGroups.map(metaApplicationGroup =>
                    <table key={metaApplicationGroup.applicationGroupName}>
                        <tbody>
                            <tr >
                                <td>
                                    <h1 style={{ verticalAlign: 'middle', textAlign: 'left', marginLeft: '35px', marginBottom: '10px', fontSize: '28px', fontWeight: 'bold', color: '#585656', marginTop: '30px' }}>
                                        {metaApplicationGroup.applicationGroupName}
                                    </h1>
                                </td>
                            </tr>
                            <tr>
                                {metaApplications.map(metaApplication => {

                                    if (metaApplication.applicationGroupName === metaApplicationGroup.applicationGroupName) {
                                        
                                        return (
                                            <td key={metaApplication.applicationName}>
                                                <Link 
                                                    to={`/b08Main?metaDataApplication=${encodeURIComponent(JSON.stringify(metaApplication))}&metaDataUser=${encodeURIComponent(JSON.stringify(metaDataUser))}`} 
                                                    style={ buttonStyle } 
                                                    className="btn btn-primary" >
                                                { metaApplication.applicationName }
                                                </Link>
                                            </td>)
                                    } else {return(null)}
                                }

                                )}
                            </tr>
                        </tbody>
                    </table>
                )}
                <footer>
                    <p>{versionText} - {publishingCoText} - <Moment format='DD.MM.YYYY, hh:mm:ss'>{date}</Moment></p>
                </footer>
            </div>
        );
    };

    const contents = loading
        ? <p><em>Loading...</em></p>
        : renderMetaApplicationView();

    return (
        <div>
            {contents}
        </div>
    );
};

export default Application;