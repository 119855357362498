import React, {Component} from 'react';
import axios from 'axios';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { StyledEngineProvider } from '@mui/material/styles';
import TasksById from './TasksById';
import secureLocalStorage from 'react-secure-storage';


export default class ProgramTask extends Component
{
    constructor(props) {
        super(props);
        this.state = { rows: [], columns: [], progId: null};

      }

      async getPrograms(tokenAuth) {

        var progs =[];
        console.log("getPrograms");
        console.log(tokenAuth);
        var config = {
            method: 'get',
            url: 'https://exploradrest.azurewebsites.net/api/v1/exploradata/GetPrograms',
            headers: {
                'Authorization': `Bearer ${tokenAuth}`,
            }
        };
        const response = await axios(config);

        progs = response.data;
        console.log("getPrograms");
        console.log(response.data);
        console.log(progs.length);

        return progs;
    }
    
    async useApiData(apiTokenAuth) {
        const columns = [];
        const apiToken = apiTokenAuth;
        console.log(apiToken);
        this.getPrograms(apiToken).then((progs)=>{
            console.log("getPrograms then");
            console.log(progs);

            if (progs){
                if (progs.length > 0){ 
                    //var columnsIn = progs[0]; 
                    const columnsIn=Object.keys(progs[0]);
                    console.log(columnsIn);
                    let i = 0;
                    while (i < columnsIn.length){
                        const val = columnsIn[i];
                        console.log("columnName: "+val); // here is your column name you are looking for
                        const col = {field: val,headerName: val, width: 200, id:i};
                        columns.push(col);
                        i++;
                        
                    }
                }else{
                    console.log("No columns");
                }

                console.log("rows");
                console.log(progs);
                console.log("columns");
                console.log(columns);
                //rows = progs;
            }

            this.setState({columns: columns, rows: progs,});
        }).catch((error)=> {
                            console.log(error); 
                            if (error.response.status === 401) {
                                //place your reentry code => login again
                                window.location = '/login';
                                //console.log("caca");
                            }});
    }

    componentDidMount(){
        const tokenString = JSON.parse(secureLocalStorage.getItem('userToken'));
        console.log("componentDidMount");
        console.log(tokenString.token);
        this.useApiData(tokenString.token);
    }

    loadTasks(id){
        console.log("loadTasks progId: "+id);
        this.setState({progId: id});
    }

    render()
    {
        //const data = useData(100,1000);
        
        console.log("datas");
        console.log(this.state.rows);
        console.log("//////////////");
        console.log(this.state.columns);
        
        return(
            <StyledEngineProvider injectFirst>
                <p></p>
                <h1>Explora Programs</h1>
                <p></p>
                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={this.state.rows}
                        columns={this.state.columns}
                        getRowId={(row) => row.programID}
                        onRowSelectionModelChange={(ids) => {
                            console.log(ids);
                            this.loadTasks(ids);
                          }}
                        showCellRightBorder={true}
                        components={{ Toolbar: GridToolbar }}
                    />
                </div>
                <div style={{ height: 400, width: '100%' }}>
                    <TasksById progId={this.state.progId}/>
                </div>
            </StyledEngineProvider>
        );
    }
    
}