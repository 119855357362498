import * as React from 'react';
import MenuItems from "./MenuItems";
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import './NavBar.css';

const Navbar = ({ metaDataApplication, onSelectedValue  }) => {
    const [menuItems, setMenuItems] = React.useState([]);
    const [secondLevelData, setSecondLevelData] = React.useState([]);
    const [dataLists, setdataLists] = React.useState([]);

    const makeAxiosRequest = async (token, url) => {
        //console.log("makeAxiosRequest")
        try {
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
            
            const response = await axios(config);
            return response.data;
        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 401) {
            window.location = '/login';
            }else{
                throw error;
            }
            
        }
    };

    React.useEffect(() => {

        if (metaDataApplication){
          const populateExploraNavMenu = async () => {
            //console.log("Explora menu Bar useEffect 1",metaDataApplication?.applicationCode);
            const tokenAuth = JSON.parse(secureLocalStorage.getItem('userToken'));
            const user = secureLocalStorage.getItem('userID');
            const data = await makeAxiosRequest(
              tokenAuth.token,
              'https://exploradrest.azurewebsites.net/api/v1/exploradata/GetDomains?userID=' +
                user +
                '&applicationCode=' +
                metaDataApplication.applicationCode
            );
      
            setMenuItems(data);
          };
          const populateExploraNavSubMenu = async () => {
            //console.log("Explora Sub menu Bar useEffect 2",metaDataApplication?.applicationCode);
              const tokenAuth = JSON.parse(secureLocalStorage.getItem('userToken'));
              const user = secureLocalStorage.getItem('userID');
              const data = await makeAxiosRequest(
                tokenAuth.token,
                'https://exploradrest.azurewebsites.net/api/v1/exploradata/GetDomainChilds?userID=' +
                  user +
                  '&applicationCode=' +
                  metaDataApplication.applicationCode
              );
        
              setSecondLevelData(data);
        
              //console.log("domainChilds ",data);
             
            };
          const populateExploraNavLists = async () => {
              //console.log("Explora Sub menu Bar useEffect 3",metaDataApplication?.applicationCode);
              const tokenAuth = JSON.parse(secureLocalStorage.getItem('userToken'));
              const user = secureLocalStorage.getItem('userID');
              const dataUser = await makeAxiosRequest(
                tokenAuth.token,
                'https://exploradrest.azurewebsites.net/api/v1/exploradata/GetUserDetails?userID=' + user
              );
              const data = await makeAxiosRequest(
                tokenAuth.token,
                'https://exploradrest.azurewebsites.net/api/v1/exploradata/GetLists?userCode=' +
                  dataUser[0].userCode +
                  '&applicationCode=' +
                  metaDataApplication?.applicationCode +
                  '&languageID=1&statusCode=V'
              );
        
              setdataLists(data);
        
              //console.log("dataLists ",dataLists);
               
          };
          
            populateExploraNavMenu();
            populateExploraNavSubMenu();
            populateExploraNavLists();
        }
    
      }, [metaDataApplication]);
    
  return (
    <nav>
      <ul className="menus">
        {menuItems.map((menu, index) => {
          const depthLevel = 0;
          return <MenuItems 
                        items={menu} 
                        subMenuItems={secondLevelData.filter((item) => item.domainName === menu.domainName)}
                        subSubMenuItems={dataLists}
                        key={index} 
                        depthLevel={depthLevel}
                        onSelectedValue={onSelectedValue}
                 />;
        })}
      </ul>
    </nav>
  );
};

export default Navbar;