import React, { useState } from 'react';
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
const SplitPaneTest = () => {
    const [sizes, setSizes] = useState([100, '30%', 'auto']);
  
    const layoutCSS = {
      height: '100%',
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'start'
    };
  
    return (
      <div style={{ height: 800 }}>
        <SplitPane
          split='vertical'
          sizes={sizes}
          onChange={setSizes}
        >
          <Pane minSize={70}>
            <div style={{ ...layoutCSS, background: '#ddd' }}>
              pane1
            </div>
          </Pane>
          <div style={{ ...layoutCSS, background: '#d5d7d9' }}>
            pane2
          </div>
        </SplitPane>
      </div>
    );
  };
  export default SplitPaneTest;