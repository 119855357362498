import SubSubMenuItems from "./SubSubMenuItems";
const SubDropdown = ({ submenus, dropdown, depthLevel, onSelectedValue }) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";
  return (
    <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
      {submenus.map((submenu, index) => (
        <SubSubMenuItems 
          items={submenu} 
          key={index}
          onSelectedValue={onSelectedValue}
        />
      ))}
    </ul>
  );
};

export default SubDropdown;