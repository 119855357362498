import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Button, TextField, Box, Grid, MenuItem } from "@mui/material";
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';

const TaskUpdate = () => {
    let navigate = useNavigate();
    const [formData, setFormData] = useState(null); // Initialize formData as null
    const { taskId } = useParams();
    console.log(taskId);
    

    const [programCodes, setProgramCodes] = useState([]);
    //const [isCanceled, setisCanceled] = useState(false);
  
    const getPrograms = async (tokenAuth) => {
      try {
        const config = {
          method: 'get',
          url: 'https://exploradrest.azurewebsites.net/api/v1/exploradata/GetPrograms',
          headers: {
            'Authorization': `Bearer ${tokenAuth}`,
          }
        };
        const response = await axios(config);
        return response.data;
      } catch (error) {
        throw error;
      }
    };

    // Call fetchFormData on component mount
    useEffect(() => {
        async function fetchProgramCodes() {
          try {
            const tokenString = JSON.parse(secureLocalStorage.getItem('userToken'));
            const programs = await getPrograms(tokenString.token);
            //console.log(programs.ProgramCode);
            setProgramCodes(programs);
          } catch (error) {
            console.error('Error fetching program codes:', error);
          }
       }
        // Fetch formData from Axios call
        const fetchFormData = async () => {
            try {
            const tokenString = JSON.parse(secureLocalStorage.getItem('userToken'));
            const headers = {
            'Authorization': `Bearer ${tokenString.token}`,
            };
            const response = await axios.get('https://exploradrest.azurewebsites.net/api/v1/exploradata/GetTask?TaskID='+taskId,{headers});
            setFormData(response.data); // Assuming the API response contains the formData object
            } catch (error) {
            console.error('Error fetching formData:', error);
            }
        };
        fetchFormData();
        fetchProgramCodes();
    }, [taskId]);
    
    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
      console.log("handleChange detected");
    };

    const cancelSubmission = () => {
      console.log("cancel");
      //setisCanceled(true);
      navigate("/tasks");
    }
  
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!formData) {
          console.error('Form data is null. Cannot submit.');
          return;
        }
        console.log('handleSubmit for taskid: '+taskId);
        console.log(JSON.stringify(formData));
        try {
          const tokenString = JSON.parse(secureLocalStorage.getItem('userToken'));
          const response = await axios.put(`https://exploradrest.azurewebsites.net/api/v1/exploradata/UpdateTask/${taskId}`, JSON.stringify(formData), {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${tokenString.token}`,
            }
          });
          if (response.status===200) {
            // Record updated successfully
            console.log('Record updated successfully.');
            navigate("/tasks");
          } else {
            // Handle errors or failed requests
            console.error('Failed to update record. '+response.status+' '+response.statusText);
          }
        } catch (error) {
          // Handle any network or fetch-related errors
          console.error('Error:', error);
        }
      
        
      };

    if (!formData) {
        // You can show a loading spinner or other loading indicator while formData is being fetched.
        return <div>Loading...</div>;
      }
  
    return (
      <form onSubmit={handleSubmit} style={{ maxWidth: 700, margin: "auto" }}>
        <h2>Update a Task</h2>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {Object.keys(formData)
              .filter(key => !key.includes("ID")).map((key) => (
              <Grid item xs={4} key={key}>
              {(() => {
                if (key === "programCode") {
                  return (
                    <TextField
                      label="ProgramCode"
                      id={key}
                      name={key}
                      value={formData[key]}
                      select
                      helperText="Please select a program"
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    >
                      {programCodes.map(code => (
                        <MenuItem key={code.programID} value={code.programCode}>
                          {code.programCode}
                        </MenuItem>
                      ))}
                    </TextField>
                  );
                } else {
                  return (
                    <TextField
                      label={key}
                      id={key}
                      name={key}
                      type="text"
                      value={formData[key]}
                      onChange={handleChange}
                      fullWidth
                      multiline
                      margin="normal"
                      variant="outlined"
                    />
                  );
                }
              })()}
            </Grid>
            ))}
          </Grid>
        </Box>
        <Button type="submit" variant="contained" color="primary" sx={{mt: 2, mr:2}}>
          Submit
        </Button>
        <Button type="cancel" variant="contained" color="primary" onClick={cancelSubmission} sx={{mt: 2}}>
          Cancel
        </Button>
      </form>
    );
  };
  
  export default TaskUpdate;