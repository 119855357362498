import React, {Component} from 'react';
import axios from 'axios';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { StyledEngineProvider } from '@mui/material/styles';
import secureLocalStorage from 'react-secure-storage';


export default class TasksById extends Component
{
    constructor(props) {
        super(props);
        this.state = { rows: [], columns: [], progId: null};
      }

      async getTasks(tokenAuth,prgId) {

        var progs =[];
        console.log("getTasks");
        console.log(tokenAuth);
        var config = {
            method: 'get',
            url: 'https://exploradrest.azurewebsites.net/api/v1/exploradata/GetTasksByProgId',
            headers: {
                'Authorization': `Bearer ${tokenAuth}`,
            },
            params: {'progId' : `${prgId}`}
        };
        const response = await axios(config);

        progs = response.data;
        console.log("getTasks");
        console.log(response.data);
        console.log(progs.length);

        return progs;
    }
    
    async useApiData(apiTokenAuth,prgId) {
        const columns = [];
        const apiToken = apiTokenAuth;
        console.log(apiToken);
        this.getTasks(apiToken,prgId).then((progs)=>{
            console.log("getTasks then");
            console.log(progs);

            if (progs){
                if (progs.length > 0){ 
                    //var columnsIn = progs[0]; 
                    const columnsIn=Object.keys(progs[0]);
                    console.log(columnsIn);
                    let i = 0;
                    while (i < columnsIn.length){
                        const val = columnsIn[i];
                        console.log("columnName: "+val); // here is your column name you are looking for
                        const col = {field: val,headerName: val, width: 200, id:i};
                        columns.push(col);
                        i++;
                        
                    }
                }else{
                    console.log("No columns");
                }

                console.log("rows");
                console.log(progs);
                console.log("columns");
                console.log(columns);
                //rows = progs;
            }

            this.setState({columns: columns, rows: progs,});
        }).catch((error)=> {
                            console.log(error); 
                            if (error.response.status === 401) {
                                //place your reentry code => login again
                                window.location = '/login';
                                //console.log("caca");
                            }});
    }

    componentDidMount(){
        console.log("progId: "+this.props.progId);
        const tokenString = JSON.parse(secureLocalStorage.getItem('userToken'));
        console.log("componentDidMount");
        console.log(tokenString.token);
        
    }

    componentDidUpdate(prevProps){
        console.log("componentDidUpdate");
        console.log("Render progId: "+this.props.progId);
        if ( this.props.progId !== prevProps.progId ) {
            const tokenString = JSON.parse(secureLocalStorage.getItem('userToken'));
            this.useApiData(tokenString.token,this.props.progId);
        }
        
    }

    render()
    {
        //const data = useData(100,1000);
        
        console.log("datas");
        console.log(this.state.rows);
        console.log("//////////////");
        console.log(this.state.columns);
        
        
        return(
            <StyledEngineProvider injectFirst>
                <p></p>
                <h1>Explora Tasks</h1>
                <p></p>
                <div style={{ height: 700, width: '100%' }}>
                    <DataGrid
                        rows={this.state.rows}
                        columns={this.state.columns}
                        getRowId={(row) => row.taskID}
                        showCellRightBorder={true}
                        components={{ Toolbar: GridToolbar }}
                    />
                </div>
            </StyledEngineProvider>
        );
    }
    
}