import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Login.css';
import { TextField, Box, Button } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';

async function loginUser(credentials) {
    return fetch('https://exploradrest.azurewebsites.net/api/v1/Login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }

export default function Login({ setToken }) {
  
  const location = useLocation();
  console.log(location.state);

  let navigate = useNavigate();

  const handleSubmit = async e => {
    e.preventDefault();
    console.log("handleSubmit");
    const data = new FormData(e.currentTarget);
    const email = data.get('email');
    const password =data.get('password');

    const token = await loginUser({
      email,
      password
    });
    console.log(token);
    //npm install react-secure-storage to test instead of local storage
    secureLocalStorage.setItem('userID',email);
    secureLocalStorage.setItem('userToken',JSON.stringify(token));
    setToken(token);

    const previousLocation = JSON.parse(secureLocalStorage.getItem('previousLocation'));
    const previousPathname = previousLocation !==null ? previousLocation.pathname !==null ? previousLocation.pathname : '/' : '/';
    console.log("previous location: "+previousPathname);
    console.log("-------------");
    navigate(previousPathname || '/');
  }

  return(
    <div className="login-wrapper">
      <h1>Please Log In</h1>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Log In
            </Button>
      </Box>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};