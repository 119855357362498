import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid, GridToolbar} from '@mui/x-data-grid';
import { StyledEngineProvider } from '@mui/material/styles';
import { Link, useLocation } from "react-router-dom";
import { Button } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';

const useApiData = (apiTokenAuth) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const getPrograms = async (tokenAuth) => {
    try {
      const config = {
        method: 'get',
        url: 'https://exploradrest.azurewebsites.net/api/v1/exploradata/GetPrograms',
        headers: {
          'Authorization': `Bearer ${tokenAuth}`,
        }
      };
      const response = await axios(config);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const apiToken = apiTokenAuth;
      try {
        const progs = await getPrograms(apiToken);
        if (progs.length > 0) {
          const columnsIn = Object.keys(progs[0]);
          const columnsData = columnsIn.filter(key => !key.includes("ID")).map((val, id) => ({
            field: val,
            headerName: val,
            width: 200,
            id,
          }));
          setColumns(columnsData);
        } else {
          console.log("No columns");
        }
        setRows(progs);
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 401) {
          window.location = '/login';
        }
      }
    };

    fetchData();
  }, [apiTokenAuth]);

  return { columns, rows };
};

const Programs = () => {
  const location = useLocation();
  secureLocalStorage.setItem('previousLocation', JSON.stringify(location));
  const tokenString = JSON.parse(secureLocalStorage.getItem('userToken'));
  const { columns, rows } = useApiData(tokenString?.token);

  return (
    <StyledEngineProvider injectFirst>
      <p></p>
      <h1>Explora Programs</h1>
      <p></p>
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.programID}
          onSelectionModelChange={(ids) => {
            console.log(ids);
          }}
          showCellRightBorder={true}
          components={{ Toolbar: GridToolbar }}
        />
      </div>
      <div>
        <Button component={Link} to="/programadd" variant="contained" color="primary" sx={{mt: 2, mr:2}}>
          Add Program
        </Button>
      </div>
    </StyledEngineProvider>
  );
};

export default Programs;