import React, { useState } from 'react';
import { Input } from '@mui/material';
import './B08.css';

function B08Edit({ dataLists, dataBcaRefTranslated }) {

  console.log("B08Edit dataLists",dataLists);
  console.log("B08Edit dataBcaRefTranslated",dataBcaRefTranslated);
  // State to hold the Input value
  const [InputValue, setInputValue] = useState('');

  // Define the onChange event handler function
  const handleInputChange = (event) => {
    // Update the InputValue state with the new value from the Input element
    setInputValue(event.target.value);
    console.log(InputValue);
  };

  /*const postChanges = () => {
    console.log(InputValue);
  }
  */
  const twoObjectsLeftDivStyle = {marginRight: '-10px'};
  const labelClassName = "control-label col-md-4 postfix";
  const layoutLabelCSS = {
                          paddingLeft: '0px',
                          marginLeft: '0px',
                          marginRight: '5px',
                          width: '40%',
                          fontWeight: 'normal',
                          textAlign: 'right',
                          color: '#000',
                          float: 'left'
                         };

  const inputClassName = "var-box-form-control text-box single-line";                     
  /*const layoutInputCSS = {
                          paddingLeft: '0px',
                          marginLeft: '0px',
                          marginRight: '5px',
                          width: '40%',
                          fontWeight: 'normal',
                          textAlign: 'right',
                          color: '#000',
                          float: 'left'};     
                          */                   
  
  return (
    <div>
              {dataBcaRefTranslated.map((itemData, index) => (
                  <div key={index} className="form-group">
                    {dataLists.filter((item) => item.elementCode === "Input").map((item, index) =>(
                      <div className="col-md-10" style={{...twoObjectsLeftDivStyle}}>
                                        {item.columnName === "RowNumber"?
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.rowNumber} onChange={handleInputChange} /></>):
                                        item.columnName === "RefCode"? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.refCode} onChange={handleInputChange} /></>): 
                                        item.columnName === "OrderBy" ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.orderBy} onChange={handleInputChange} /></>): 
                                        item.columnName === "RefEnglishText" ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.refEnglishText} onChange={handleInputChange} /></>): 
                                        item.columnName === "DefaultValue" ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.defaultValue} onChange={handleInputChange} /></>): 
                                        item.columnName === "RefEnglishDesc" ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.refEnglishDesc} onChange={handleInputChange} /></>): 
                                        item.columnName === "TranslationShortText" ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationShortText} onChange={handleInputChange} /></>): 
                                        item.columnName === "TranslationText" ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText} onChange={handleInputChange} /></>): 
                                        item.columnName === "TranslationHint" ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationHint} onChange={handleInputChange} /></>):
                                        item.columnName === 'RefEnglishText' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.refEnglishText}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText01' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText01}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText02' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText02}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText03' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText03}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText04' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText04}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText05' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText05}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText06' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText06}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText07' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText07}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText08' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText08}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText09' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText09}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText10' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText10}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText11' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText11}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText12' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText12}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText13' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText13}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText14' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText14}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText15' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText15}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText16' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText16}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText17' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText17}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText18' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText18}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText19' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText19}  onChange={handleInputChange} /></>):
                                        item.columnName === 'TranslationText20' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.translationText20}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar01' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar01}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar02' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar02}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar03' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar03}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar04' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar04}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar05' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar05}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar06' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar06}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar07' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar07}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar08' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar08}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar09' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar09}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar10' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar10}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar11' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar11}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar12' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar12}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar13' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar13}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar14' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar14}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar15' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar15}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar16' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar16}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar17' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar17}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar18' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar18}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar19' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar19}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar20' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar20}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar21' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar21}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar22' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar22}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar23' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar23}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar24' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar24}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar25' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar25}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar26' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar26}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar27' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar27}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar28' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar28}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar29' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar29}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar30' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar30}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar31' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar31}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar32' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar32}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar33' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar33}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar34' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar34}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar35' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar35}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar36' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar36}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar37' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar37}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar38' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar38}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar39' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar39}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar40' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar40}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar41' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar41}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar42' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar42}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar43' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar43}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar44' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar44}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar45' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar45}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar46' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar46}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar47' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar47}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar48' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar48}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar49' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar49}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar50' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar50}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar51' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar51}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar52' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar52}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar053' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar53}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar54' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar54}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar55' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar55}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar56' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar56}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar57' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar57}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar58' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar58}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar59' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar59}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar60' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar60}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar61' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar61}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar62' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar62}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar62' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar63}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar64' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar64}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar65' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar65}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar66' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar66}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar67' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar67}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar68' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar68}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar69' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar69}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar70' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar70}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar71' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar71}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar72' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar72}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar73' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar73}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar74' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar74}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar75' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar75}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar76' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar76}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar77' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar77}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar78' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar78}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar79' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar79}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar80' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar80}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar81' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar81}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar82' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar82}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar83' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar83}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar84' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar84}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar85' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar85}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar86' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar86}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar87' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar87}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar88' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar88}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar89' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar89}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar90' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar90}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar91' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar91}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar92' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar92}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar93' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar93}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar94' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar94}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar95' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar95}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar96' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar96}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar97' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar97}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar98' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar98}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Varchar99' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.varchar99}  onChange={handleInputChange} /></>):
                                        item.columnName === 'NVarchar01' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.nVarchar01}  onChange={handleInputChange} /></>):
                                        item.columnName === 'NVarchar02' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.nVarchar02}  onChange={handleInputChange} /></>):
                                        item.columnName === 'NVarchar03' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.nVarchar03}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float01' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float01}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float02' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float02}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float03' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float03}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float04' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float04}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float05' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float05}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float06' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float06}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float07' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float07}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float08' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float08}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float09' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float09}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float10' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float10}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float11' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float11}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float12' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float12}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float13' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float13}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float14' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float14}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float15' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float15}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float16' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float16}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float17' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float17}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float18' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float18}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float19' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float19}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float20' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float20}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float21' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float21}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float22' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float22}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float23' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float23}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float24' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float24}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float25' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float25}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float26' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float26}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float27' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float27}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float28' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float28}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float29' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float29}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float30' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float30}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float31' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float31}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float32' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float32}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float33' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float33}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float34' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float34}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float35' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float35}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float36' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float36}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float37' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float37}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float38' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float38}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float39' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float39}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float40' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float40}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float41' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float41}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float42' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float42}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float43' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float43}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float44' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float44}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float45' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float45}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float46' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float46}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float47' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float47}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float48' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float48}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float49' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float49}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float50' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float50}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float51' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float51}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float52' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float52}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float053' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float53}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float54' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float54}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float55' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float55}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float56' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float56}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float57' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float57}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float58' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float58}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float59' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float59}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float60' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float60}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float61' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float61}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float62' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float62}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float62' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float63}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float64' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float64}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float65' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float65}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float66' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float66}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float67' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float67}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float68' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float68}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float69' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float69}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float70' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float70}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float71' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float71}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float72' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float72}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float73' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float73}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float74' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float74}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float75' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float75}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float76' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float76}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float77' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float77}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float78' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float78}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float79' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float79}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float80' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float80}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float81' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float81}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float82' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float82}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float83' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float83}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float84' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float84}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float85' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float85}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float86' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float86}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float87' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float87}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float88' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float88}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float89' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float89}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float90' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float90}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float91' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float91}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float92' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float92}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float93' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float93}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float94' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float94}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float95' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float95}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float96' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float96}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float97' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float97}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float98' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float98}  onChange={handleInputChange} /></>):
                                        item.columnName === 'Float99' ? 
                                        (<><label className={labelClassName} style={{...layoutLabelCSS}} id={item.columnName+"-"+index}>{item.label}</label><Input className={inputClassName} type="text" value={itemData.float99}  onChange={handleInputChange} /></>):null
                                        }
                                  </div>
                                  ))}
                  </div>
                ))}
        </div>
  );
}

export default B08Edit;