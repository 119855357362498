import SubMenuItems from "./SubMenuItems";
const Dropdown = ({ submenus, subsubmenus, dropdown, depthLevel, onSelectedValue }) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";
  return (
    <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
      {submenus.map((submenu, index) => (
        <SubMenuItems 
            items={submenu} 
            subMenuItems={subsubmenus.filter((item) => item.domainChildCode === submenu.domainChildCode)} 
            key={index} 
            depthLevel={depthLevel} 
            onSelectedValue={onSelectedValue}
        />
      ))}
    </ul>
  );
};

export default Dropdown;