import React, { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function StorageSelect({onSelect}) {
  const [storage, setStorage] = useState('Az');

  const handleChange = (event) => {
    console.log(event.target.value);
    setStorage(event.target.value);
    if (event.target.value ==="Az"){
      onSelect('uploadreact');
    }else if (event.target.value==="S3"){
      onSelect('beeplusexploras3');
    }else{
      onSelect('na');
    }
    
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="storage-select-label">Storage type</InputLabel>
        <Select
          labelId="storage-select-label"
          id="storage-select"
          value={storage}
          label="Storage type"
          onChange={handleChange}
        >
          <MenuItem value={"Az"}>Azure Storage</MenuItem>
          <MenuItem value={"S3"}>AWS S3</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}