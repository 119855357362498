import React, { useState, useEffect } from 'react';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const B08DropDownItem = ({ listCode, languageID, label,columnName, index, defaultCode,onSelectChange}) => {
    const [dropDownList, setdropDownList] = useState([]);
    const [itemValue, setItemValue] = React.useState(defaultCode!==null ? defaultCode : '');
    
    //console.log("defaultCode",defaultCode);

    const makeAxiosRequest = async (token, url) => {
        //console.log("makeAxiosRequest")
        try {
            const config = {
                method: 'get',
                url: url,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            };
            
            const response = await axios(config);
            return response.data;

        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 401) {
            window.location = '/login';
            }else{
                throw error;
            }
            
        }
      };
      
      useEffect(() => {
        
        const populateDropDownLists = async (listCode,languageID) => {
            //console.log("listCode",listCode);
            //console.log("languageID",languageID);
            const tokenAuth = JSON.parse(secureLocalStorage.getItem('userToken'));
            
            const data = await makeAxiosRequest(
            tokenAuth.token,
            'https://exploradrest.azurewebsites.net/api/v1/exploradata/PopulateDropDownList?' +
                'listCode=' +
                listCode +
                '&languageID=' + languageID
            );
            
            setdropDownList(data);

            }
        
        populateDropDownLists(listCode,languageID);//.then(console.log("Label "+label, dropDownList));
        

    }, [listCode, languageID]);

    const handleChange = (event, columnName) => {
        //console.log("columnName : ",columnName);
        //console.log(event.target.value);
        
        onSelectChange(columnName, event.target.value);
        setItemValue(event.target.value);
    };

    return (
            <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id={"input"+label+"-"+index}>{label}</InputLabel>
                <Select
                    defaultValue={defaultCode}
                    labelId={"select"+label+"-"+index}
                    id={"select"+label+"-"+index}
                    value={itemValue}
                    label={label}
                    onChange={(e) => handleChange(e,columnName)}
                >
                {dropDownList.map((element,index) => (
                    <MenuItem key={element.lovText+index} value={element.lovCode}>{element.lovText}</MenuItem>
                ))}
                </Select>
            </FormControl>
    );
};

export default B08DropDownItem;