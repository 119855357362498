import React from 'react';

export default function Dashboard() {
  return(
    <div className="login-wrapper">
      <h1>Explora Scheduler App</h1>
      <p></p>
      <h2>manage Programs and Tasks in Explora scheduler</h2>
      <p></p>
      <h3>wtf.SOB Framework 2023</h3>
    </div>
    
  );
}