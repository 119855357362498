import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import Login from './components/Login/Login';
import useToken from './components/useToken';
import Programs from './components/Programs/Programs';
import Tasks from './components/Programs/Tasks';
import Actions from './components/Programs/Actions';  
import NavMenu from './components/NavBar/NavBar';
import ProgramTask from './components/Programs/ProgramTask'
import ProgramAdd from './components/Programs/ProgramAdd';
import TaskAdd from './components/Programs/TaskAdd';
import TaskUpdate from './components/Programs/TaskUpdate';
import Application from './components/Explora/Application';
import B08Main from './components/Explora/pages/B08Main';
import FileSystemNavigator from './components/Explora/FileManager/FileSystemNavigator';
import SplitPaneTest from './components/Explora/pages/SplitPaneTest';

function App() {
  const { token, setToken } = useToken();
  
  const handleLogoff = () => {
    // Clear token and perform other cleanup
    setToken("");
    // Optionally clear user-specific data
    // Optionally perform other cleanup tasks

    // Navigate to the login page
    // This approach uses react-router's Navigate component
    return <Navigate to="/login" />;
  };

  const PrivateRoute = ({ element, ...rest }) => {
    return token ? element : <Navigate to="/login" />;
  };

  return (
    <div>
      <BrowserRouter>
        <NavMenu token={token} onLogoff={handleLogoff}/>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/programs" element={<PrivateRoute element={<Programs />} />} />
          <Route path="/tasks" element={<PrivateRoute element={<Tasks />} />} />
          <Route path="/actions" element={<PrivateRoute element={<Actions />} />} />
          <Route path="/programtask" element={<PrivateRoute element={<ProgramTask />} />} />
          <Route path="/login" element={<Login setToken={setToken} />} />
          <Route path="/programadd" element={<PrivateRoute element={<ProgramAdd />} />} />
          <Route path="/taskadd" element={<PrivateRoute element={<TaskAdd />} />} />
          <Route path="/application" element={<PrivateRoute element={<Application />} />} />
          <Route path="/b08main" element={<PrivateRoute element={<B08Main />} />} />
          <Route path="/files" element={<PrivateRoute element={<FileSystemNavigator />} />} />
          <Route path="/splitpane" element={<SplitPaneTest />} />
          {/*<Route path="/b08mainTst" element={<PrivateRoute element={<B08MainTst />} />} />*/}
          {/* Uncomment this route if needed */}
          {<Route path="/update-task/:taskId" element={<PrivateRoute element={<TaskUpdate />} />} />}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;