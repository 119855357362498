import { useRef } from "react";

const SubSubMenuItems = ({ items,onSelectedValue }) => {

  let ref = useRef();

  const handleItemClick = (metaDataList) => {
    //
    console.log("SubSubMenu handle click");
    const selectedItem = { listName: metaDataList.listName, listID: metaDataList.listID}; // Replace with actual selected item
    onSelectedValue(selectedItem);
  };

  return (
    <li
      className="menu-items"
      ref={ref}
    >
        <button onClick={() => handleItemClick(items)}>{items.listName}</button>
    </li>
  );
};

export default SubSubMenuItems;