import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { StyledEngineProvider } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';

// Custom hook for fetching tasks
const useApiData = (apiTokenAuth) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const getTasks = async (tokenAuth) => {
      var progs = [];
      console.log("getTasks");
      console.log(tokenAuth);
      var config = {
        method: 'get',
        url: 'https://exploradrest.azurewebsites.net/api/v1/exploradata/GetTasks',
        headers: {
          'Authorization': `Bearer ${tokenAuth}`,
        }
      };
      const response = await axios(config);

      progs = response.data;
      console.log("getTasks");
      console.log(response.data);
      console.log(progs.length);

      return progs;
    };

    const fetchData = async () => {
      const apiToken = apiTokenAuth;
      try {
        const progs = await getTasks(apiToken);
        if (progs) {
          if (progs.length > 0) {
            const columnsIn = Object.keys(progs[0]);
            //no id columns
            const columnsData = columnsIn.filter(key => !key.includes("ID")).map((val, id) => ({
              field: val,
              headerName: val,
              width: 200,
              id,
            }));
            setColumns(columnsData);
          }
          setRows(progs);
        }
      } catch (error) {
        console.log(error);
        if (error.response?.status === 401) {
          window.location = '/login';
        }
      }
    };

    fetchData();
  }, [apiTokenAuth]);

  return { columns, rows };
};

const Tasks = () => {
  const location = useLocation();
  secureLocalStorage.setItem('previousLocation', JSON.stringify(location));
  const tokenString = JSON.parse(secureLocalStorage.getItem('userToken'));
  const { columns, rows } = useApiData(tokenString?.token);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSelectionChange = useCallback((newSelection) => {
    // Update the selectedRow state when the selection changes
    if (newSelection.length > 0) {
      const selectedTask = rows.find((row) => row.taskID === newSelection[0]);
      setSelectedRow(selectedTask);
    } else {
      // If no row is selected, set selectedRow to null
      setSelectedRow(null);
    }
    console.log("handleSelectionChange");
    //console.log(selectedRow.taskID);
  }, [rows]);

  const handleUpdateClick = useCallback(() => {
    // Check if a row is selected before processing the update
    if (selectedRow?.taskID !== null) {
      const selectedTask = rows.find((row) => row.taskID === selectedRow.taskID);
      setSelectedRow(selectedTask);
      //console.log(`Selected Task ID: ${selectedTask.taskID}`);
      //console.log(`Selected row ID: ${selectedRow}`);
      //if (selectedTask) {
      // Perform the update operation for the selected task
      //alert(`Selected Task ID: ${selectedTask.taskID}`);
      // Replace the alert with your custom logic for updating the task
      //}
    } else {
      setSelectedRow(null);
      alert('Please select a task to update.');
    }
  }, [rows, selectedRow]);

  return (
    <StyledEngineProvider injectFirst>
      <p></p>
      <h1>Explora Tasks</h1>
      <p></p>
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.taskID}
          showCellRightBorder={true}
          onSelectionModelChange={handleSelectionChange} // Add event listener for row selection
          selectionModel={[selectedRow?.taskID]} // Set the selected row
          editMode="row"
        />
      </div>
      <div>
        <Button component={Link} to="/taskadd" variant="contained" color="primary" sx={{mt: 2, mr:2}}>
          Add Task
        </Button>
        <Link
          to={{
            pathname: `/update-task/${selectedRow?.taskID}`,
          }}
          style={{ textDecoration: 'none' }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateClick}
            disabled={selectedRow === null} // Disable the button when no row is selected
            sx={{mt: 2, mr:2}}
          >
            Update Task
          </Button>
        </Link>
      </div>
    </StyledEngineProvider>
  );
};

export default Tasks;